<template>
    <div v-if="isshow">
        <!-- 积分列表详情 -->
        <div class="mall-details-wrap">
            <!--提示信息开始-->
            <div class="buy-hint vertical-center">
                <img src="~assets/images/index/hint.png" class="hint-icon"/>
                <div class="hint-tit">请勿刷分，违者积分清零</div>
            </div>
            <!--提示信息结束-->

            <!--商品轮播图开始-->
            <div class="banner-wrap">

                <van-swipe class="banner-box" :autoplay="3000" @change="bindchangeSwiper" indicator-color="#FF6B29">
                    <van-swipe-item class="align-center" v-for="(item,index) in details.preview_picture" :key="index">
                        <img :src="item" class="banner-img"/>
                    </van-swipe-item>
                </van-swipe>
                <div class="banner-indexes" v-if="details.preview_picture">
                    {{ swiper_num }}/{{ details.preview_picture.length }}
                </div>
                <!--          <div class="vip-icon" v-if="details.is_vip === 1"><img src="../../../assets/images/user/vip-icon.png" alt=""></div>-->
            </div>
            <!--商品轮播图结束-->

            <!--商品信息开始-->
            <div class="commodity-info">
                <div class="commodity-config vertical-center space-between">
                    <div class="integral-box vertical-center">
                        <div class="integral-vip" v-if="details.is_vip == 1">会员专享</div>
                        <span class="integral-num theme-color">{{ details.credit }}积分</span>
                         <img :src="details.user_label.picture" v-if="details.user_label && details.user_label.picture" class="labelimg">
                    </div>
                    <div class="repertory-box" v-if="(parseFloat(details.stock) - parseFloat(details.sold)) > 0">仅剩<span class="theme-color">{{ parseFloat(details.stock) - parseFloat(details.sold) }}</span>件
                    </div>
                    <div class="repertory-box" v-else>售罄</div>
                    <div class="sales-num">销量{{ details.sold }}</div>
                </div>
                <div class="commodity-tit-box">
                    <div class="commodity-tit-box-t">
                    <div class="integral-leave" v-if="details.rule">Lv{{ details.rule.detail.name }}</div>
                    <div class="commodity-tit white-space-2">{{ details.title }}</div>
                    </div>
                  
                    <div class="commodity-sub white-space-2">{{ details.subtitle }}</div>
                </div>
            </div>
            <!--商品信息结束-->
            <div class="line-bac"></div>

            <!--商品详情和兑换开始-->
            <div class="details-exchange">
                <van-tabs v-model="active" custom-class="details-exchange-nav" title-inactive-color="#666666"
                          title-active-color="#EB6100">
                    <van-tab title="商品详情">
                        <div class="detail-box rich-text" v-html="details.about"></div>
                    </van-tab>
                    <van-tab title="兑换记录">
                        <div class="record-item">
                            <van-list
                              :finished="finished"
                              :finished-text="logData.length > 0 ? '没有更多了' : '暂时没有相关信息'"
                            >
                                <div class="record-list vertical-center space-between" v-for="(item,index) in logData" :key="index">
                                    <div class="vertical-center">
                                        <!--                    <img :src="item.wechat.avatar ? item.wechat.avatar : $confStatic.avatar" class="list-img"/>-->
                                        <img class="list-img" v-if="item.wechat && item.wechat.avatar" :src="item.wechat.avatar" />
                                        <avatar :username="item.wechat ? item.wechat.nickname : '酸谈社群'" class="list-img" v-else ></avatar>
                                        <div class="list-info">
                                            <div class="name">{{ item.wechat ? item.wechat.nickname : '酸谈社群' }}</div>
                                            <div class="time">{{ item.created_at }}</div>
                                        </div>
                                    </div>
                                    <div class="detail-num">-{{ item.credit }}积分</div>
                                </div>
                            </van-list>
                        </div>
                    </van-tab>
                </van-tabs>
            </div>
            <!--商品详情和兑换结束-->
        </div>

        <div class="bot-height safety-height"></div>
        <div class="mall-show-bot safety-height vertical-center space-between">
            <div class="bot-l">
                <div class="mall-num-box theme-color">
                    <span class="mall-num">{{ details.credit ? details.credit : 0 }}</span>
                    积分
                </div>
                <div class="user-info vertical-center">
                    <div class="user-integral">余额：{{ userInfo && userInfo.credit ? userInfo.credit : 0 }}积分</div>
                    <div class="user-grade theme-color">Lv.{{ userInfo && userInfo.level ? userInfo.level : 0 }}</div>
                </div>
            </div>
<!--                 :class="{ 'forbid-bg' : !conversion_status}" -->
            <div class="exchange-btn"  @click="exchangeBtn">兑换</div>
        </div>

        <!--确认兑换提示开始-->
        <van-overlay :show="confirm_pop" z-index="999">
            <div class="overlay-confirm-exchange-con">
                <div class="overlay-confirm-exchange">
                    <div class="overlay-level-head">
                        <img class="overlay-level-img" src="~assets/images/user/overlay-bg.png"/>
                        <div class="overlay-level-title">
                            <div class="overlay-level-title-con">
                                <div class="overlay-level-title-text">兑换提示</div>
                                <img class="overlay-level-title-img" @click="confirm_pop = false"
                                     src="~assets/images/user/overlay-cancel.png"/>
                            </div>
                        </div>
                    </div>
                    <div class="overlay-exchange-body">
                        <img class="overlay-exchange-tip-img" src="~assets/images/user/overlay-gift.png"/>
                        <div class="overlay-exchange-tip-text text-justify-t">{{ hint_tit }}</div>
                        <div class="overlay-btn">
                            <div class="cancel" @click="confirm_pop = false">取消</div>

                            <div class="confirm" v-if="inquireInfo != null && inquireInfo.code == 1000" @click="inquireFun(true)">兑换</div>
                            <div class="confirm" v-else @click="confirm_pop = false">确认</div>
                        </div>
                    </div>
                </div>
            </div>
        </van-overlay>
        <!--确认兑换提示结束-->

        <!--线下课兑换提示开始-->
        <van-overlay :show="offline_activity_status" z-index="999">
            <div class="overlay-offline-activities-con">
                <div class="overlay-offline-activities">
                    <div class="overlay-level-head">
                        <img class="overlay-level-img" src="~assets/images/user/overlay-bg.png"/>
                        <div class="overlay-level-title">
                            <div class="overlay-level-title-con">
                                <div class="overlay-level-title-text">兑换提示</div>
                                <img class="overlay-level-title-img" @click="offline_activity_status = false" src="~assets/images/user/overlay-cancel.png"/>
                            </div>
                        </div>
                    </div>
                    <div class="overlay-activities-body">
                        <div class="overlay-activities-tip-text">兑换线下活动需完善以下信息：</div>
                        <div>
                            <div class="overlay-activities">
                                <div class="overlay-activities-name"><span>*</span>姓名</div>
                                <input class="overlay-activities-input" name="name"
                                       placeholder-class="overlay-team-input-placeholder" @blur="blurFun" type="text" maxlength="16" v-model="name" placeholder="您的姓名"/>
                            </div>
                            <div class="overlay-activities">
                                <div class="overlay-activities-name"><span>*</span>联系电话</div>
                                <input class="overlay-activities-input" name="mobile"
                                       placeholder-class="overlay-team-input-placeholder" @blur="blurFun" type="tel" oninput="if(value.length>11)value=value.slice(0,11)" maxlength="11" v-model="mobile" placeholder="您的电话"/>
                            </div>
                            <div class="overlay-btn">
                                <div class="cancel" @click="offline_activity_status = false">取消</div>
                                <div class="confirm" @click="confirmLineClass">兑换</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </van-overlay>
        <!--线下课兑换提示结束-->
    </div>
  <van-loading size="24px" v-else class="align-center">加载中...</van-loading>
</template>

<script>
import _ from 'lodash'

export default {
    name: "show",
    data() {
        return {
            active: 0,
            uuid: '',
            details: {},
            swiper_num: 1,
            logData: [],
            finished: true,
            conversion_status: false, // 兑换状态
            confirm_pop: false,
            offline_activity_status: false,
            hint_tit: '确认兑换商品成功后，不可以退换哦',
            period_id: '',
            name: '',
            mobile: '',
            inquireInfo: null, // 查询结果
            isshow:false,
        }
    },
    created() {
        this.isshow = false
        // 判断地址缓存
        if (localStorage.getItem('address_f')) {
            localStorage.removeItem('address_f')
        }

        if (this.$route.params.uuid) {
            this.uuid = this.$route.params.uuid;
            this.getDetails();
        } else {
            this.$router.push({path: '/malls'})
        }
        this.$route.meta.backUrl = '/malls';
    },
    methods: {
        // 获取商品详情
        getDetails() {
            let url = this.$api.Goods + '/' + this.uuid;
            this.$http.get(url, this.$cache.getIsLogin()).then(res => {
                if (res.data.success) {
                    let Info = res.data.data;
                    this.details = res.data.data;
                    this.orderLog(this.details.id);

                    /*设置meta*/
                    this.$store.commit('M_TITLE', Info.title)
                }else {
                    this.$store.dispatch('A_ERROR_TEXT',{
                        code_num: '404',
                        error_text: res.data.message
                    })
                    setTimeout(()=>{
                        this.$router.push({path: '/error'})
                    },500)
                }
                this.isshow = true
            })
        },
        // 商品图发生改变时
        bindchangeSwiper(index) {
            this.swiper_num = index + 1
        },
        //  获取商品兑换记录
        orderLog(id) {
            let url = this.$api.OrdersLog + '?id=' + id;
            this.$http.get(url, this.$cache.getIsLogin()).then(res => {
                if (res.data.success) {
                    this.logData = res.data.data;
                }
            })
        },
        //  兑换方法
        exchangeBtn() {
          if(!this.$cache.getIsLogin()) {
            this.$store.commit('M_LOGIN_POP', true)
          }else {
            this.inquireFun(false)
          }
        },

        //  查询商品兑换
        inquireFun: _.debounce(function (link_status) {
            // if(this.inquireInfo != null && this.inquireInfo.code != 1000){
            //     this.confirm_pop = true;
            //     return false
            // }
            let url = this.$api.OrdersConfirm + '/' + this.details.uuid;
            this.$http.get(url, true).then(res => {
                if (res.data.success) {
                    this.inquireInfo = res.data.data;
                    //  显示状态
                    if(!link_status){
                        this.confirm_pop = true;
                    }
                    if (this.inquireInfo.code == 1000) {
                        // 判断商品类型
                        if(link_status){
                            if (res.data.data.period_id) {
                                this.period_id = res.data.data.period_id;
                            }
                            if (this.details.type == 'entity') {
                                this.$router.push({
                                    path: '/order/confirm',
                                    query: {
                                        f: this.$handle.encryption({uuid: this.details.uuid})
                                    }
                                })

                            } else {
                                if (this.details.virtual_detail.category == 'offline_activity') {
                                    this.hint_tit = '确认兑换商品成功后，不可以退换哦';
                                    this.confirm_pop = false;
                                    this.offline_activity_status = true
                                } else {
                                    this.confirm_pop = false;
                                    this.storeFun();
                                }
                            }
                        }
                        else {
                          this.hint_tit = '确认兑换商品成功后，不可以退换哦';
                        }
                    }else {
                        this.hint_tit = res.data.data.message;
                        this.conversion_status = false;
                    }
                }
            })
        }, 500),
        //  线下课兑换
        confirmLineClass(){
            let _this = this;
            if(this.name == ''){
                this.$toast('请输入姓名')
                return false
            }else if(this.mobile == ''){
                this.$toast('请输入手机号');
                return false
            }
            this.storeFun()
        },
        //  兑换提交
        storeFun: _.debounce(function () {
            let url = this.$api.OrdersStore;
            let data = {
                id: this.details.id.toString(),
            }
            if (this.details.virtual_detail.category == 'offline_activity') {
                data.name = this.name
                data.mobile = this.mobile
            }
            this.$http.post(url, data, true).then(res => {
                if (res.data.success) {
                    if (this.period_id) {
                        this.$router.push({path: `/courses/trainings/group/${this.period_id}`})
                    } else {
                        if(res.data.data.type == 'offline_activity'){
                            this.$router.push({path: '/order/succeed',query:{ code: res.data.data.code }})
                        }else if (res.data.data.type == 'resource'){
                          this.$router.push({path: '/order/succeed',query:{ link: res.data.data.resource.url,linkCode: res.data.data.resource.code }})
                        }else {
                            this.$router.push({path: '/order/succeed'})
                        }
                    }
                } else {
                    this.inquireInfo.code = -1;
                    this.hint_tit = res.data.message;
                    this.$toast(res.data.message);
                }
            }).catch(error=>{
                if (error.response.status == 422) {
                    this.$toast(error.response.data.message);
                }
            })
        }, 500),
        // 失焦方法
        blurFun(){
            window.scroll(0, 0);
        }
    },
    deactivated() {
        this.$store.commit('M_TITLE', '')
    },
    watch: {
        userInfo(val, oldVal) {
            return val;
        }
    },
    computed: {
        userInfo() {
            return this.$store.getters.userInfo;
        }
    },
  beforeRouteEnter(to,from,next){
    if (from.path == "/malls"){
      from.meta.keepAlive = true
      to.meta.keepAlive = true
      next()
    }
      next()
  },
  activated(){
    this.isshow = false
    // 判断地址缓存
    if (localStorage.getItem('address_f')) {
      localStorage.removeItem('address_f')
    }
    if (this.$route.params.uuid) {
      this.uuid = this.$route.params.uuid;
      this.getDetails();
    } else {
      this.$router.push({path: '/malls'})
    }
    this.$route.meta.backUrl = '/malls';
  }
  // beforeRouteLeave(to,from,next){
  //     console.log(to.path)
  //   if (to.path == "/malls"){
  //     from.meta.keepAlive = true
  //     // if (!to.meta.keepAlive){
  //       to.meta.keepAlive = true
  //     // }
  //     next()
  //   }else {
  //     from.meta.keepAlive = false
  //     next()
  //   }
  // },
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/malls/show.scss";
//确认兑换提示开始
.overlay-confirm-exchange-con {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .overlay-confirm-exchange {
        width: 600/$r;
        //height: 560/$r;
        background: #FFFFFF;
        border-radius: 20/$r;
        padding-bottom: 51/$r;

        .overlay-level-head {
            position: relative;
            width: 600/$r;
            height: 127/$r;
            background: linear-gradient(250deg, #FC9161 0%, #FF844D 100%);
            border-radius: 20/$r 20/$r 0/$r 0/$r;

            .overlay-level-img {
                width: 600/$r;
                height: 100%;
            }

            .overlay-level-title {
                position: absolute;
                top: 0;
                left: 0;

                .overlay-level-title-con {
                    position: relative;
                    width: 600/$r;
                    height: 127/$r;
                }

                .overlay-level-title-text {
                    font-size: 32/$r;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 127/$r;
                    width: 100%;
                    text-align: center;
                }

                .overlay-level-title-img {
                    position: absolute;
                    top: 50/$r;
                    right: 40/$r;
                    width: 35/$r;
                    height: 34/$r;
                }
            }
        }

        .overlay-exchange-body {
            display: flex;
            flex-direction: column;
            align-items: center;

            .overlay-exchange-tip-img {
                width: 159/$r;
                height: 163/$r;
                margin: 31/$r 0 37/$r 0;
            }

            .overlay-exchange-tip-text {
                margin: 0 26/$r;
                font-size: 28/$r;
                font-weight: 500;
                color: #333333;
                line-height: 36/$r;
            }

            .overlay-btn {
                display: flex;
                margin-top: 48/$r;
                font-size: 28/$r;
                font-family: Source Han Sans CN;
                font-weight: 300;
                line-height: 74/$r;
                color: #999999;

                .cancel {
                    width: 240/$r;
                    height: 74/$r;
                    margin-right: 42/$r;
                    background: #FFFFFF;
                    border: 1px solid #B5B5B5;
                    border-radius: 37/$r;
                    text-align: center;
                }

                .confirm {
                    width: 240/$r;
                    height: 74/$r;
                    background: #FF6B29;
                    border-radius: 37/$r;
                    text-align: center;
                    color: #FFFFFF;
                }
            }
        }
    }
}

//确认兑换提示结束

//线下课兑换提示开始
.overlay-offline-activities-con {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .overlay-offline-activities {
        width: 600/$r;
        //height: 560/$r;
        background: #FFFFFF;
        border-radius: 20/$r;
        padding-bottom: 51/$r;

        .overlay-level-head {
            position: relative;
            width: 600/$r;
            height: 127/$r;
            background: linear-gradient(250deg, #FC9161 0%, #FF844D 100%);
            border-radius: 20/$r 20/$r 0/$r 0/$r;
            .overlay-level-img {
                width: 600/$r;
                height: 100%;
            }
            .overlay-level-title {
                position: absolute;
                top: 0;
                left: 0;

                .overlay-level-title-con {
                    position: relative;
                    width: 600/$r;
                    height: 127/$r;
                }

                .overlay-level-title-text {
                    font-size: 32/$r;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 127/$r;
                    width: 100%;
                    text-align: center;
                }

                .overlay-level-title-img {
                    position: absolute;
                    top: 50/$r;
                    right: 40/$r;
                    width: 35/$r;
                    height: 34/$r;
                }
            }
        }

        .overlay-activities-body {
            display: flex;
            flex-direction: column;
            align-items: center;

            .overlay-activities-tip-text {
                padding-top: 37/$r;
                margin-bottom: 22/$r;
                font-size: 28/$r;
                font-weight: 500;
                color: #333333;
                line-height: 36/$r;
            }

            .overlay-activities {
                display: flex;
                align-items: center;
                margin: 0 auto;
                width: 469/$r;
                border-bottom: 1/$r solid #BFBFBF;
                padding: 38/$r 0 20/$r 0;
                .overlay-activities-name {
                    min-width: 126/$r;
                    margin-right: 40/$r;
                    font-size: 28/$r;
                    font-weight: 500;
                    color: #333333;
                    line-height: 36/$r;
                    span {
                        color: #FF0000;
                        min-width: 126/$r;
                    }
                }
                .overlay-activities-input {
                    width: 303/$r;
                    padding-bottom: 4/$r;
                    border: none;
                    font-size: 24/$r;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #333333;
                    line-height: 24/$r;
                }

                .overlay-team-input-placeholder {
                    font-weight: 300;
                    color: #999999;
                    font-size: 24/$r;
                }
            }

            .overlay-btn {
                display: flex;
                margin-top: 44/$r;
                font-size: 28/$r;
                font-family: Source Han Sans CN;
                font-weight: 300;
                line-height: 74/$r;
                color: #999999;

                .cancel {
                    width: 240/$r;
                    height: 74/$r;
                    margin-right: 42/$r;
                    background: #FFFFFF;
                    border: 1px solid #B5B5B5;
                    border-radius: 37/$r;
                    text-align: center;
                }

                .confirm {
                    width: 240/$r;
                    height: 74/$r;
                    //background: #FF6B29;
                    background: #FF6B29;
                    border-radius: 37/$r;
                    text-align: center;
                    color: #FFFFFF;
                }
            }
        }
    }
}

//线下课兑换提示结束
</style>